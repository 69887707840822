@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Porkh';
  src: url('./assets/fonts/PORKH___.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('./assets/fonts/Hind-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #fffdfa;
  @apply dark:bg-stone-950;
}

.icon-container {
  transition: color 0.3s;
}

.group:hover .icon {
  @apply text-emerald-500;
  @apply hover:animate-spin;
}

.icon {
  @apply text-pink-300;
}

.hover-border:hover {
  border-color: #6bbd60;
}

.toggleDarkBtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: inline-block;
  width: 65px;
  height: 41px;
}
.toggleDarkBtn input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slideBtnTg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(85, 85, 85);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.slideBtnTg:before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  left: 5px;
  bottom: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: pink;
}
input:checked + .slideBtnTg {
  background-color: #fde68a;
}
input:checked + .slideBtnTg:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slideBtnTg.round {
  border-radius: 34px;
}
.slideBtnTg.round:before {
  border-radius: 50%;
}
